<template>
  <div id="AddUser" class="row mx-0">
    <div class="row mx-0">
      <div id="header" class="col-sm-12 col-md-12 color-bg-4">
        <Header
                :name="user.fullname"
                /> 
      </div>
      <div class="col-sm-12 col-md-12">
        <div id="content" class="row color-bg-4">
           <div id="" class="">
            <SideMenu :indexsec="3"/>
          </div>
          <div id="stn-edituser" class="stn_edituserAdd">
            <form
              @submit.prevent="
                submit(
                  name_form
                )
              "
            >
            <div class="div_EProduct">
              <p>AGREGAR POSICIÓN</p>
              </div>


              <div class="row mx-0 row-one">
                <div class="EPF1_C1centrado">
                  <label class="labelN color-1" for="inputN">Nombre</label>
                  <b-form-input
                   pattern="[a-zA-ZñÑáéíóúÁÉÍÓÚ0-9\s]{2,254}"
                    v-model="name_form"
                    required
                    id="inputN"
                    type="text"
                  />
                </div>
              
              </div>


               <div class="row mx-0 row-one">
                <div class="div-error" v-if="this.status=='error'">
                    <p class="msg-error">{{this.msg}}</p>
                </div>

              </div>
              <div class="btn-form">
                <b-btn 
                  variant="outline-secondary"
                  class="editar-modal Button_Center"
                  type="submit"
                  >GUARDAR</b-btn
                >
                <b-modal
                  id="modal-editar"
                  ref="modal-editar"
                  no-close-on-backdrop
                  centered
                  hide-footer
                  hide-header
                >
                  <div class="message-modal">
                    <img :src="IconSuccess" class="success" /><br />
                    <p class="text-modal color-6">El tipo de jugador se agrego correctamente</p>
                  </div>
                  <div class="">
                    

                     <div class="btns-dflx">
                      <div class="btn-void"></div>

                   <b-button class="btn-modal" @click="editarOtro()"
                      >AÑADIR OTRO</b-button
                    >
                      <b-button class="btn-modal" @click="toEdit()"
                        >EDITAR</b-button
                      >
                    </div>


                    <b-button
                      type="button"
                      aria-label="Close"
                      class="close-modal"
                      @click="salir()"
                      >×</b-button
                    >
                  </div>
                </b-modal>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SideMenu from '../../components/SideMenu';
import logo from '../../assets/cimarrones/logocima_2.png';
import IconSuccess from "../../assets/Vector.png";
import legrafica from "../../assets/Legrafica.png";
import categorias from '../../assets/categorias.png';
import usuarios from '../../assets/usuarios.png';
import productos from '../../assets/productos.png';
import Header from '../../components/Header';
export default {
  name: "AddType",
    components: {
    Header,
    SideMenu,
  },
  data() {
    return {
      name_form: "", 
      status: "",
      msg: "",
      file1:null,
      logo: logo,
      legrafica: legrafica,
      IconSuccess: IconSuccess,
      added :'',
      categorias: categorias,
      usuarios: usuarios,
      productos: productos,
    };
  },async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
  created() {},
  computed:{
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
  },
  methods: {
    wait: function () {
      setTimeout(() => this.$router.go(), 200);
    },
    toEdit: function () {
       this.$router.push("/editar/tipo/"+this.added.id).catch((err) => {});
    },
    submit: async function ( name) {
       
            let data = {"name": name}
            
          this.status=''
          this.msg=''
       
          let result = await this.$store.dispatch("main/addItem",  {option:'type', item: data});
        
          if(result.status=='error'){
            this.status='error'
            this.msg= result.message
          }else{ // success
          this.added = result.added
              this.showModal() 
          }
       
         
     
     
    },
    editarOtro: function () {
      this.wait();
    },
    salir: function () {
       this.$router.push("/administrador").catch((err) => {});
    },
    showModal(){
       this.$refs['modal-editar'].show()
    }
  },
};
</script>


